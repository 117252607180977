import React from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

let state = ''
let transcription = ''

const getAndResetTranscription = (transcript, resetTranscript) => {
  console.log("prev: " + transcript)
  state ='Get Transcript'
  return new Promise(resolve => setTimeout(() => {
    resolve()
  }, 2000))
    .then(() => {
      console.log("post: " + transcript)
      resetTranscript()
      transcription = transcript
      return transcript
    })
}

const getTranscription = () => {
  return transcription
}

const startListening = resetTranscript => {
  SpeechRecognition.startListening({
    language: 'es-CR',
    continuous: true
  })
  state ='started'
  resetTranscript()
  return "started"
}

const stopListening = resetTranscript => {
  state ='started'
  SpeechRecognition.stopListening()
  resetTranscript()
  return "stoped"
}

const Dictaphone = props => {
  const { transcript, resetTranscript } = useSpeechRecognition()
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }
  window.getAndResetTranscription = () => getAndResetTranscription(transcript, resetTranscript)
  window.start = () => startListening(resetTranscript)
  window.stop = () => stopListening(resetTranscript)
  window.getTranscription = getTranscription
  return (
    <div>
      <p>{state}</p>
      {transcript}
    </div>
  )
}
export default Dictaphone