import './App.css'
import Transcript from './Transcript'
import React from 'react'

class App extends React.Component {
  componentDidMount() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function(stream) {
        console.log('You let me use your mic!')
      })
      .catch(function(err) {
        console.log('No mic for you!')
      })
  }

  render() {
    return (
      <div className="App">
        <Transcript />
      </div>
    )
  }
}

export default App
